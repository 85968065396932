<!-- 教务 -- 考勤管理 -- 教师考勤记录 -- 详情 -->
<template>
  <div class="flex" style="height: 100%">
    <div class="form-body" style="padding-top: 40rem">
      <div class="flex flex-align" style="margin-bottom: 40rem;padding-left: 35rem">
        <p style="font-size: 20rem;font-weight: bold">打卡详情</p>
        <el-image
            v-if="detailsData.punch_in_status_preview === '正常'"
            :src="success"
            fit="contain"
            style="width: 28rem; height: 28rem;margin: 0 10rem 0 30rem">
        </el-image>
        <el-image
            v-if="detailsData.punch_in_status_preview === '迟到'"
            :src="warning"
            fit="contain"
            style="width: 28rem; height: 28rem;margin: 0 10rem 0 30rem">
        </el-image>
        <el-image
            v-if="detailsData.punch_in_status_preview !== '正常' && detailsData.punch_in_status_preview !== '迟到'"
            :src="error"
            fit="contain"
            style="width: 28rem; height: 28rem;margin: 0 10rem 0 30rem">
        </el-image>
        <!--        <el-image-->
        <!--            v-if="detailsData.punch_in_status_preview === '未打卡'"-->
        <!--            style="width: 28rem; height: 28rem;margin: 0 10rem 0 30rem"-->
        <!--            :src="error"-->
        <!--            fit="contain">-->
        <!--        </el-image>-->
        <i style="font-size: 14rem;color: rgba(0,0,0,.4)">{{ detailsData.punch_in_status_preview }}</i>
      </div>
      <el-form ref="form" label-width="170rem">
        <div style="padding-left: 36rem">
          <el-form-item label="迟到时间:">
            <p>{{ detailsData.be_late_time_preview }}</p>
          </el-form-item>
          <el-form-item label="日期（星期）:">
            <p>{{ detailsData.check_date_preview }}</p>
          </el-form-item>
          <el-form-item label="课节:">
            <p>{{ detailsData.section_cn }}</p>
          </el-form-item>
          <el-form-item label="教室:">
            <p>{{ detailsData.classroom_name_preview }}</p>
          </el-form-item>
          <el-form-item label="打卡时间:">
            <p>{{ detailsData.punch_in_time_preview }}</p>
          </el-form-item>
          <el-form-item label="学生出勤数/学生总数:">
            <p>{{ detailsData.attend_num }}/{{ detailsData.student_num }}</p>
          </el-form-item>
          <el-form-item label="是否代课:">
            <p>{{ detailsData.is_agent == 1 ? '是' : '否' }}</p>
          </el-form-item>
          <el-form-item label="是否补卡:">
            <p>{{ detailsData.is_supplementary ? '是' : '否' }}</p>
          </el-form-item>
          <el-form-item label="补卡审批状态:">
            <p>{{ detailsData.supplementary_status }}</p>
          </el-form-item>
          <el-form-item label="补卡审批时间:">
            <p>{{ detailsData.supplementary_approve_time }}</p>
          </el-form-item>
          <el-form-item label="图片:">
            <el-image
                v-for="item in detailsData.supplementary_imgs"
                :preview-src-list="detailsData.supplementary_imgs"
                :src="item"
                fit="contain"
                style="width: 160rem; height: 160rem;margin-right: 20rem">
            </el-image>
          </el-form-item>
        </div>
      </el-form>
      <div class="footer-button">
        <el-button type="primary" @click="$router.back()">返回</el-button>
      </div>
    </div>
    <div class="m-right">
      <div>
        <img alt="" src="@/assets/img/bg-2.png">
        <h4>个人信息</h4>
        <div v-if="true">
          <p><span>姓名</span><i>{{ detailsData.teacher_name }}</i></p>
          <p><span>校区</span><i>{{ detailsData.school_name }}</i></p>
          <p><span>工号</span><i>{{ detailsData.th_num }}</i></p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import success from '@/assets/img/details-success.png'
import error from '@/assets/img/details-error.png'
import warning from '@/assets/img/details-wraning.png'

export default {
	_config:{"route":{"path":"details","meta":{"title":"详情","breadStyle":{"backgroundImage":"linear-gradient(180deg, #FAFBFF 0%, #FBFBFF 100%)","display":"none"}}}},
  data() {
    return {
      warning,
      error,
      success,
      detailsData: '',
    }
  },
  created() {
    this.getData()
  },
  methods: {
    getData() {
      this.$_axios2.get('api/attendance/teacher-attendance/details?id=' + this.$route.query.id).then(res => {
        this.detailsData = res.data.data
      })
    }
  },
}
</script>

<style lang="scss" scoped>
::v-deep .el-form-item__label {
  text-align: left;
}

i {
  font-size: 14rem;
}

.knowledge {
  align-items: center;
  display: flex;
  justify-content: center;
  margin-top: 24rem;

  .knowledge_box {
    background-color: #f4f4f4;
    display: flex;
    height: 60rem;
    padding-top: 12rem;
  }

  .add_knowledge {
    display: flex;
    padding-left: 40rem;
  }
}

.m-right {
  background: #f7f7f7;
  height: 100%;
  margin: 0 40rem 0 0;
  overflow: hidden;
  padding-left: 20rem;
  position: relative;
  width: 320rem;

  & > div {
    background: #fff;
    height: 100%;
    padding: 30rem 0 0 30rem;
    width: 320rem;
  }

  h4 {
    font-size: 20rem;
    margin-bottom: 40rem;
  }

  img {
    height: 88rem;
    left: 20rem;
    position: absolute;
    top: 0;
    width: 100%;
  }

  p {
    color: rgba(0, 0, 0, .6);
    //padding-left: 20rem;
    margin-top: 20rem;
  }

  span {
    color: rgba(0, 0, 0, .8);
    display: inline-block;
    font-size: 14rem;
    width: 54rem;
  }
}

@media only screen and (max-width: 1500rem) {
  .knowledge {
    width: 1200rem !important;
  }
}
</style>
